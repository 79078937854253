@import url(https://fonts.googleapis.com/css?family=Lato:400,700);

$green: gray;
$blue: #94C2ED;
$orange: #E38968;
$gray: #92959E;

*, *:before, *:after {
  box-sizing: border-box;
}



.containerr {
  margin: 0 auto;
  width: 100%;
  background: #444753;
  border-radius: 5px;
}

.people-list {
  width:25%;
  float: left;
  
  .search {
   width: auto;
    display: flex;
    position: relative;
    padding: 5px;
    // justify-content: center;
  }
  
  .searchInput {
    border-radius: 3px;
    border: none;
    padding: 14px;
    color: white;
    background: #6A6C75;
    width: 100%;
    font-size: 14px;
  }
  
  .fa-search {
    // position: relative;
    top: 18px;
    position: absolute;
    right: 10px;
  }
  
  ul {
    padding: 20px;
    height: 770px;
    overflow-y: scroll;
  

    li {
      padding-bottom: 20px;
    }
  }
  
  img {
    float: left;
  }
  
  .about {
    float: left;
    margin-top: 8px;
  }
  
  .about {
    padding-left: 8px;
  }
  
  .status {
    color: $gray;
  }
  
}
.anticon {
  position: relative;
  top: -5px;
}
.chat {
  width: 100%;
  float:left;
  // background: #FFFFFF;
  background-image: url("./chat.jpg");
  z-index: -1;
//   background: rgb(2,0,36);
// background: linear-gradient(90deg, rgba(2,0,36,1) 21%, rgba(17,73,84,1) 100%);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  
  color: #434651;
  
  .chat-header {
    position: relative;
    background-color:#1e1e2d;
    border-top-right-radius: 13px;
    padding: 8px 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-bottom: 1px solid rgb(212, 208, 208);
    
    img {
      float: left;
    }
    
    .chat-about {
      float: left;
      padding-left: 10px;
      margin-top: 6px;
    }
    
    .chat-with {
      font-weight: bold;
      font-size: 16px;
    }
    
    .chat-num-messages {
      color: $gray;
    }
    
    .fa-star {
      float: right;
      color: #D8DADF;
      font-size: 20px;
      margin-top: 12px;
    }
  }
  
  .chat-history {
    padding: 30px 30px 20px;
    border-bottom: 2px solid white;
    height: 730px;
    overflow-y: scroll;
    
    .message-data {
      margin-bottom: 15px;
    }
    
    .message-data-time {
      color: lighten($gray, 8%);
      padding-left: 6px;
    }
    
    .message {      
      color: white;
      padding: 18px 20px;
      line-height: 26px;
      font-size: 16px;
      border-radius: 7px;
      margin-bottom: 30px;
      // width: 90%;
      position: relative;
      
      &:after {
        bottom: 100%;
        left: 7%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-bottom-color: $green;
        border-width: 10px;
        margin-left: -10px;
      }
    }
    
    .my-message {
      background: $green;
    }
    
    .other-message {
      background: $blue;
      
      &:after {
        border-bottom-color: $blue;
        left: 93%;
      }
    }
    
  }
  
  .chat-message {
    
   
    
    .fa-file-o, .fa-file-image-o {
      font-size: 16px;
      color: gray;
      cursor: pointer;
      
    }
    
 
  }
}

.online, .offline, .me {
    margin-right: 3px;
    font-size: 10px;
  }
  
.online {
  color: $green;
}
  
.offline {
  color: $orange;
}

.me {
  color: $blue;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.float-right {
  float: right;
}

.clearfix:after {
	visibility: hidden;
	display: block;
	font-size: 0;
	content: " ";
	clear: both;
	height: 0;
}
.name{
  color: white;
  margin-top: 5px;
}
.chat-history::-webkit-scrollbar{
  display: none !important;
}
.chatimg{
  width: 200px;
  height: 200px;
}

.msger-inputarea {
  display: flex;
  padding: 10px;
  border-top: var(--border);
  background: #eee;
  position: relative;
}
.msger-inputarea * {
  padding: 10px;
  border: none;
  border-radius: 3px;
  font-size: 1em;
}

.msger-send-btn {
  margin-left: 10px;
  background: rgb(0, 196, 65);
  color: #fff;
  font-weight: bold;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background 0.23s;
}
.msger-send-btn:hover {
  background: rgb(0, 180, 50);
}
.list::-webkit-scrollbar{
  display: none !important;
}
.searchInput:focus{
  border: none;
}





.bottom-bar{
  background: #eee;
}
.msger-input{
  width: 100%;
  border: 1px solid gray;
  padding: 5px;
}
.msger-input:focus{
  border: 1px solid gray;
}
.MuiSpeedDial-fab{
  background: white !important;
  position: relative;
  top: 16px;
  height: 100px !important;
  box-shadow: none !important;
  border-radius: 0% !important;
}
.userChatImg{
  width: 45px;
  height: 45px;
  border-radius: 50%;
}
.lableerupload{
  width: 100%;
  height: 100%;
  padding-bottom: 9px;
    padding-top: 13px;
}
.imgcontainer{
  position: relative;
  width: 50%;
  max-width: 300px;
}
.imageloading{
  display: block;
  width: 200px;
  height: 200px;
}
.overlayr{
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  transition: .5s ease;
  opacity:1;
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.vedioloading{
  display: block;
  width: 270px;
  height: 200px;
}
.overlayrr{
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  transition: .5s ease;
  opacity:1;
  width: 270px;
  height: 200px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.imagecontainercat{
  position: relative;
  height: 200px;
  width: 270px;
  display: inline-block;
  // width: auto;
}
.imagecontainercat2{
  position: relative;
  height: 200px;
  width: 270px;
  display: inline-block;
  // width: auto;
}
.imagecontainercat2>div{
  height: 200px;
  width: 100%;
}
.closeSvg{
  position: absolute;
  color: red;
  font-size: 2rem;
  right: -10px;
  top: -12px;
  cursor: pointer;

}
.closeSvg2{
  position: absolute;
  color: red;
  font-size: 2rem;
  right: -10px;
  top: 10px;
  cursor: pointer;

}
.closeSvg3{
  position: absolute;
  color: red;
  font-size: 2rem;
  left: -10px;
  top: -12px;
  cursor: pointer;

}
.closeSvg4{
  position: absolute;
  color: red;
  font-size: 2rem;
  left: -10px;
  top: -12px;
  cursor: pointer;

}