.chat-window {
  /* height: 400px; */
  height: 100vh;
  overflow-y: scroll; /* Enable vertical scrolling for overflowed content */
  padding: 10px;
}

.chat-bubble {
  display: flex;
  align-items: flex-end;
  max-width: 80%;
}

.user {
  flex-direction: row-reverse;
}

.other {
  flex-direction: row;
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #007bff;
}

.other-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #6c757d;
}

.message-content {
  background-color: #f1f1f1;
  border-radius: 10px;
  padding: 10px;
  margin-left: 10px;
}

.name {
  font-weight: bold;
}

.message {
  margin-top: 5px;
}

.input-bar {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  position: fixed;
  bottom: 0;
  width: 90%;
  background-color: white;
  border-top: 1px solid #ccc;
}

input[type='text'] {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
}

button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
