.annousmentCardCont{
    width: 100%;
    display: flex;
    justify-content: center;
}
.annousmentCard{
    width: 100%;
    min-height: 200px;
    background-color: #FFFFFF;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    border-top-right-radius: 30px;
    margin-top: 10px;
    display: flex;
    position: relative;

}
.announcementImage{
    height: 200px;
    width: 40%;
    display: block;
}
.announcementImage>img{
    height: 100%;
    width: 100%;
}
.anouncementTitle{
    width:70%;
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
}
.anouncementTitle>div:nth-child(1){
    text-align: center;
}
.anouncementTitle>div>p{
    font-size: 16px;
}
.annoutbutton{
    display: flex;
    justify-content: center;
    position: absolute;
    /* align-items: center; */
    bottom: 0;
    /* left: 106px; */
    right: 140px;
    margin-bottom: 11px;
}