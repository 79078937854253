:root {
    --background-gradient: linear-gradient( #ffffff 30% , #ffffff);
    --gray: #34495e;
    --darkgray: #2c3e50;
  }
  
.wrapp{
    display: flex;
    /* justify-content:space-evenly; */
}

  select {
    /* Reset Select */
    appearance: none;
    outline: 0;
    border: 0;
    box-shadow: none;
    /* Personalize */
    flex: 1;
    padding: 0 1em;
    color: #fff;
    background-color: var(--darkgray);
    background-image: none;
    cursor: pointer;
  }
  /* Remove IE arrow */
  select::-ms-expand {
    display: none;
  }
  /* Custom Select wrapper */
  .select {
    position: relative;
    display: flex;
    width: 20em;
    height: 3em;
    border-radius: .25em;
    overflow: hidden;
  }
  /* Arrow */
  .select::after {
    content: '\25BC';
    position: absolute;
    top: 0;
    right: 0;
    padding: 1em;
    /* background-color: #34495e; */
    transition: .25s all ease;
    pointer-events: none;
  }
  /* Transition */
  .select:hover::after {
    color: #f39c12;
  }
  