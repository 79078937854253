.text-color{
    color:#ffffff;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
}
.footericons{
    align-self: flex-end;
}
.paositionOfIcon{
    position: absolute;
    bottom: 12px;
}

.selectuser:hover{
    background-color: rgb(231, 226, 226);
}


