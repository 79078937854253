
:root {
  --grey-1: #2a2a2b;
  --grey-2: #494848;
  --grey-3: #131312;
  --grey-4: #333334;
  --grey-5: #EBEBEB;
  --color-1: #007AFF; /*#1F09AC*/
  --font-1: "Roboto", sans-serif;
}






















 .comment {
  min-height: 50px;
  width: 100%;
  min-width: 40px;
  float: left;
  margin: 5px 0px;
  text-align: left;
}

 .me {
  float: right;
  text-align: right;
}

 .comment .chataimage {
  filter: grayscale(1);
  height: 50px;
  width: auto;
  width: 50px;
  border-radius: 50%;
  float: left;
}

 .me .chataimage {
  float: right;
}

 .bubble {
  display: inline-block;
  background: var(--color-1);
  padding: 8px 12px;
  border-radius: 8px;
  color: white;
  font-family: var(--font-1);
  max-width: 55%;
  min-width: 175px;
  position: relative;
  margin: 0px 15px;
}

 .me .bubble {
  background: var(--grey-5);
  text-align: left;
  font-size: 14px;
  color: #333;
}
.other{
	font-size: 14px;
}
 .other .bubble:before {
  content: "";
  top: 0px;
  left: -25px;
  position: absolute;
  background: transparent;
  height: 0px;
  width: 0px;
  border-left: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-right: 20px solid var(--color-1);
}

 .me .bubble:after {
  content: "";
  position: absolute;
  top: 0px;
  right: -25px;
  background: transparent;
  height: 0px;
  width: 0px;
  border-right: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 20px solid var(--grey-5);
}

.bubble .chataimage {
  border-radius: 5px;
  height: 200px;
  width: auto;
  filter: none;
}

 .bubble a {
  color: white;
}








  
   .bubble1 {
	display: inline-block;
	background: var(--color-1);
	padding: 8px 12px;
	border-radius: 8px;
	color: white;
	font-family: var(--font-1);
	max-width: 55%;
	min-width: 175px;
	position: relative;
	margin: 0px 15px;
  }
  
   .me .bubble1 {
	background: var(--grey-5);
	text-align: left;
	font-size: 14px;
  }
  
   .other .bubble1:before {
	content: "";
	top: 0px;
	left: -25px;
	position: absolute;
	background: transparent;
	height: 0px;
	width: 0px;
	border-left: 15px solid transparent;
	border-bottom: 15px solid transparent;
	border-right: 20px solid var(--color-1);
  }
  
   .me .bubble1:after {
	content: "";
	position: absolute;
	top: 0px;
	right: -25px;
	background: transparent;
	height: 0px;
	width: 0px;
	border-right: 15px solid transparent;
	border-bottom: 15px solid transparent;
	border-left: 20px solid var(--grey-5);
  }
  
  .bubble1 .chataimage {
	border-radius: 5px;
	height: 200px;
	width: auto;
	filter: none;
  }
  
   .bubble1 a {
	color: white;
  }
  .colorChange:hover{
    background-color: rgb(228, 223, 223);
    cursor: pointer;
  }
  .groupImageContainer{
    display: inline-block;
    height: 171px;
    width: 300px;
    padding-right: 38px;
  }
  .groupImageContainer>img{
    width: 100%;
    height: 100%;
  }
  








